.checker-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.checker-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.checker-container {
	display: flex;
	flex-direction: column;
}

.checker-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
	justify-content: left;
}

.checker-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.retrieve-button {
	font-family: Rubik;
	font-size: 16px;
	color: white;
	background-color: #121212;
	height: 50px;
	width: 300px;
	margin-top: 15px;
	margin-bottom: 15px;
	border-radius: 7px;
	text-decoration: none;
	transition: background-color 100ms ease-out;
}

.retrieve-button:hover {
	background-color: #3a3a3a;
}

@media (max-width: 1024px) {
	.checker-title {
		width: 100% !important;
	}

	.checker-subtitle {
		width: 100% !important;
	}
}
